import {newCOLORS} from 'core/styles';
import styled from 'styled-components';

export const ContainerStyles = styled.div`
    margin-bottom: 15px;
    max-width: 250px;
    width: 100%;
    position: relative;
    ${(props) => (props.$css ? props.$css : '')}
`;

const showError = (props) => {
    return props.meta ? !props.meta.active && props.meta.error : false;
};

export let InputStyled = styled.input`
    border-radius: 4px;
    border: 1px solid ${(props) => (showError(props) ? newCOLORS.warning : '#eee')};
    background: #fff;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    padding: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    width: 100%;

     &:disabled {
        opacity: 0.5;
        background: ${newCOLORS.gray100};
    }
    ${(props) => (props.$css ? props.$css : '')}
`;
