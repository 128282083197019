import React from 'react';

import {get} from 'utils/helpers';
import LabelStyled from '../Label/LabelStyled';
import {ContainerStyles, InputStyled} from './styles';
import {ErrorStyled} from '../../styles';

const TextInputStyled = (props) => {
    const {
        required = false,
        className = 'text-input',
        labelClassName = 'text-input__label',
        inputClassName = 'text-input__input',
        label = '',
        type = 'text',
    } = props;
    const error = get(props, 'meta.error');
    const labelClass = label ? labelClassName : 'text-input__label--hidden';

    let inputProps = Object.assign(
        props.input ? props.input : {},
        props.disabled ? {disabled: props.disabled} : {},
        props.defaultValue ? {defaultValue: props.defaultValue} : {},
        typeof props.value === 'string' ? {value: props.value} : {},
    );

    if (inputProps.defaultValue && inputProps.value) {
        console.warn(props.name, ' has both default value and value');
    }

    if (props.handleRef) {
        inputProps.ref = props.handleRef;
    }

    if (props.handleBlur) {
        inputProps.onBlur = props.handleBlur;
    }

    if (props.name) {
        inputProps.name = props.name;
    }

    return (
        <ContainerStyles $css={props.$css} className={className}>
            <LabelStyled required={required} $css={props.labelCss} htmlFor={props.name}>
                {props.label}
            </LabelStyled>
            {error && <ErrorStyled>{error}</ErrorStyled>}
            <InputStyled
                {...inputProps}
                disabled={props.disabled}
                $css={props.inputCss}
                placeholder={props.placeholder}
                type={type}
                onChange={(e) => {
                    if (props.handleChange) props.handleChange(e);
                    if (props.input && props.input.onChange) {
                        let inputVal = e.target.value;
                        if (props.numbersOnly) {
                            inputVal = inputVal.replace(/[^0-9]/gi, '');
                        }
                        props.input.onChange(inputVal);
                    }
                }}
            />
        </ContainerStyles>
    );
};

export default TextInputStyled;
